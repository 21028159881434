@import '/src/assets/scss/fonts';
@import '/src/assets/scss/mixins';

// Font family
.u-light {
  font-family: $PoppinsLight, sans-serif;
}

.u-regular {
  font-family: $Poppins, sans-serif;
}

.u-medium {
  font-family: $PoppinsMedium, sans-serif;
}

.u-semiBold {
  font-family: $PoppinsSemibold, sans-serif;
}

.u-bold {
  font-family: $PoppinsBold, sans-serif;
}

// Font size and line height
h1,
.u-h1 {
  @include fontH1;
}

h2,
.u-h2 {
  @include fontH2;
}

h3,
.u-h3 {
  @include fontH3;
}

h4,
.u-h4 {
  @include fontH4;
}

h5,
.u-h5 {
  @include fontH5;
}

.u-body1 {
  @include fontBody1;
}

.u-body2 {
  @include fontBody2;
}

.u-body3 {
  @include fontBody3;
}

.u-body4 {
  @include fontBody4;
}
// Text decorations
.u-underline {
  text-decoration: underline;
  text-underline-offset: 2px;
}

// Margins
.u-m4-bot {
  margin-bottom: 4px;
}

.u-m8-bot {
  margin-bottom: 8px;
}

.u-m16-bot {
  margin-bottom: 16px;
}

.u-m24-bot {
  margin-bottom: 24px;
}

// Font color
h1,
h2,
h3,
h4,
h5,
h6,
.u-text--primary {
  color: var(--text-primary);
}

p,
.u-text--content {
  color: var(--content-color);
}

p > a {
  color: var(--accent);
}

.u-text--error {
  color: $error40;
}

.u-text--warning {
  color: $warning40;
}

.u-text--success {
  color: $success40;
}

// Text alignment
.u-text--center {
  text-align: center;
}

.u-text--right {
  text-align: right;
}

// Text transform
.u-uppercase {
  text-transform: uppercase;
}

// Cursor
.u-cursor--pointer {
  cursor: pointer;
}

.u-fill {
  &-primary {
    fill: var(--text-primary);
  }
  &-content {
    fill: var(--content-color);
  }
}

.input__error {
  color: $error40;
  overflow-wrap: break-word;
  @include fontBody3;
}

.u-flex {
  display: flex;

  &-align-center {
    align-items: center;
  }

  &-vertical {
    flex-direction: column;
  }
}

.u-gap-4 {
  gap: 4px;
}

.u-italic {
  font-style: italic;
}
