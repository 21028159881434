@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.how-to-wrapper {
  gap: 60px;
  display: flex;
  padding: 0px 72px;
  flex-direction: column;

  @media (max-width: $screen-xl-max) {
    gap: 48px;
    width: 100%;
    padding: 0px;
  }

  .link-contrast {
    background: transparent;
    color: var(--btn-link-contrast-color);
  }

  .header-items {
    gap: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .header {
    margin-bottom: 30px;

    @media (max-width: $screen-xl-max) {
      margin-bottom: 48px;
    }

    @media (max-width: $screen-md-max) {
      margin-bottom: 0px;
      text-align: center;

      h2 {
        line-height: 40px;
        font-size: 28px;
      }
    }
  }

  .features {
    left: 50%;
    width: 100vw;
    padding: 64px;
    display: flex;
    margin-top: 40px;
    position: relative;
    align-items: center;
    flex-direction: column;
    transform: translateX(-50%);
    background-color: var(--info-box);

    @media (max-width: $screen-xl-max) {
      padding: 24px;

      h3 {
        font-size: 24px;
        text-align: center;
      }
    }

    .cards {
      display: grid;
      margin-top: 50px;

      @media (min-width: $screen-lg-max) {
        margin-top: 0px;
        grid-template-columns: repeat(1, 1fr);
      }

      @media (min-width: $screen-xl-max) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: $screen-lg-max) {
        margin-top: 0px;
      }

      .card {
        gap: 12px;
        padding: 24px;
        display: flex;
        text-align: center;
        border-radius: 8px;
        align-items: center;
        flex-direction: column;

        svg {
          margin-bottom: 12px;
        }

        @media (max-width: $screen-lg-max) {
          padding: 0px;
          margin-top: 0px;
          padding-top: 16px;
        }
      }
    }
  }

  .guide {
    margin-top: 13px;
    margin-bottom: 91px;

    @media (max-width: $screen-xl-max) {
      margin-bottom: 0px;
    }

    .steps-section {
      gap: 77px;
      display: flex;
      margin-top: 86px;

      .img-wrapper {
        img {
          max-width: 680px;
          max-height: 400px;
        }
      }

      @media (max-width: $screen-xl-max) {
        gap: 56px;
        margin-top: 32px;
        align-items: center;
        flex-direction: column-reverse;

        .img-wrapper {
          img {
            width: 100%;
            max-height: 400px;
          }
        }
      }
      @media (max-width: $screen-md-max) {
        img {
          max-width: 500px;
        }
      }

      @media (max-width: $screen-sm-max) {
        img {
          max-width: 350px;
        }
      }
    }
  }

  .footer {
    gap: 64px;
    left: 50%;
    width: 100vw;
    padding: 85px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    transform: translateX(-50%);
    background-color: var(--secondary-bg);

    @media (max-width: $screen-lg-max) {
      gap: 40px;
      text-align: center;
      padding: 64px 16px;
      h3 {
        font-size: 24px;
      }
    }

    .footer-cards {
      gap: 24px;
      display: grid;
      max-width: 670px;

      @media (min-width: $screen-sm-max) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media (min-width: $screen-lg-max) {
        grid-template-columns: repeat(2, 1fr);
      }

      .card {
        gap: 24px;
        padding: 24px;
        display: flex;
        align-items: center;
        border-radius: 24px;
        flex-direction: column;
        background-color: var(--app-bg);
        border: 1px solid var(--border-color);

        button {
          width: 100%;
          margin-top: auto;
        }
      }
    }
  }
}
